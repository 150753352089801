<template>
    <div class="d-flex align-items-center justify-content-between gap-2">
        <platon-btn :loading="isEimzoLoading" class="rounded btn btn-warning flex-grow-1" @click.native="pickKey">
            <i class="fas fa-key mr-1"></i>
            <span :class="{ 'eimzo-btn-text': hasIdCard }">
                {{ $l("platon.signin_using_eimzo", "ЭРИ билан кириш") }}
            </span>
        </platon-btn>
        <platon-btn
            v-if="hasIdCard"
            :loading="isIDCardLoading"
            class="rounded btn btn-warning flex-grow-1 ml-2"
            @click.native="pickIDCard"
        >
            <i class="fab fa-usb mr-1"></i>
            <span class="eimzo-btn-text"> {{ $l("platon.signin_using_idcard", "ID-Card билан кириш") }} </span>
        </platon-btn>
    </div>
</template>

<script>
import EimzoProviderMixin from "@Platon/components/login/EimzoProviderMixin"

export default {
    name: "EImzoProvider",
    mixins: [EimzoProviderMixin],

    data() {
        return {
            client: null,
            hasIdCard: false,
            intervalId: null
        }
    },

    created() {
        this.client = this.getClient()

        this.client.install().then(() => {
            this.intervalId = setInterval(this.checkHasIdCard, 5000)

            this.checkHasIdCard()
        })
    },

    beforeDestroy() {
        clearInterval(this.intervalId)
    },

    methods: {
        async checkHasIdCard() {
            this.hasIdCard = await this.client.isIDCardPlugged()
        }
    }
}
</script>

<style>
.eimzo-btn-text {
    font-size: 0.9rem;
}
</style>
